<template>
  <!-- make a user profile card -->
  <v-card v-if="user" outlined>
    <v-card-text>
      <div class="d-flex flex-column align-center" style="width: 100%">
        <AppImg :size="60" :src="user.media[0]" />
        <div class="mt-2">
          <h4>{{ user.name }}</h4>
        </div>
        <div>
          <span>{{ user.email }}</span>
        </div>
        <div class="mt-1">
          <b v-if="isProfessional" class="primary--text"> Profissional </b>
        </div>
      </div>
    </v-card-text>
    <v-card-actions class="d-flex justify-center pt-0" v-if="!isOwner">
      <v-btn small color="primary" @click="$emit('edit')">
        <app-icon>edit</app-icon> Editar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import AppImg from "../../app/sections/AppImg.vue";
export default {
  components: { AppImg },
  props: {
    user: {
      default: {},
    },
  },

  computed: {
    isOwner() {
      return this.user.company_user.is_owner;
    },

    isProfessional() {
      return this.user.company_user.is_professional;
    },
  },
};
</script>

<style>
</style>