<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    scrollable
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <v-card>
      <v-card-title>{{ title }}</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-6 pb-6">
        <v-row v-if="!isInsert">
          <v-col class="d-flex justify-center">
            <AppImg class="mr-4" :size="150" :src="form.media[0]" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <app-text-field label="Nome" v-model="form.name" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <app-text-field label="Email" v-model="form.email" />
          </v-col>
        </v-row>
        <!-- phone wa field -->
        <v-row>
          <v-col>
            <app-whatsapp-field label="WhatsApp" v-model="form.phone_wa" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <app-password-field label="Senha" v-model="form.password" />
          </v-col>
          <v-col cols="12" md="6">
            <app-password-field
              label="Senha"
              v-model="form.password_confirmation"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pb-0">
            <div style="font-size: 16px">Tipo de usuário</div>
          </v-col>
          <v-col class="d-flex justify-space-between pt-0 pb-0">
            <v-chip-group
              :hide-details="false"
              v-model="form.company_user.is_professional"
              mandatory
            >
              <v-chip outlined filter :value="false">Atendente</v-chip>
              <v-chip outlined filter :value="true">Profissional</v-chip>
            </v-chip-group>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-switch
              v-model="form.company_user.is_admin"
              label="É Administrador"
              class="pt-0"
              hide-details
            />
            <template v-if="form.company_user.is_admin">
              <v-alert class="mt-2" type="info" text>
                Administradores tem acesso e podem alterar tudo no sistema
              </v-alert>
            </template>
            <template v-else>
              <!-- agenda -->
              <div class="mt-8" style="font-size: 16px">
                <app-icon class="mr-2">event</app-icon>
                Agenda
              </div>
              <v-switch
                v-model="form.company_user.permissions.can_manage_all_calendar"
                class="pt-0 mt-2"
                label="Pode alterar toda a agenda"
                hide-details
              />
              <template v-if="form.company_user.is_professional">
                <v-switch
                  v-model="
                    form.company_user.permissions.can_manage_own_calendar
                  "
                  class="pt-0"
                  label="Pode alterar somente a sua agenda"
                  hide-details
                  :disabled="
                    form.company_user.permissions.can_manage_all_calendar
                  "
                />
              </template>

              <!-- clientes -->
              <div class="mt-8" style="font-size: 16px">
                <app-icon class="mr-2">people</app-icon>
                Clientes
              </div>
              <v-switch
                class="pt-0 mt-2"
                v-model="form.company_user.permissions.can_store_customers"
                label="Pode cadastrar clientes"
                hide-details
              />

              <!-- financeiro -->
              <div class="mt-8" style="font-size: 16px">
                <app-icon class="mr-2">monetization_on</app-icon>
                Financeiro
              </div>

              <!-- <v-switch
                class="pt-0 mt-2" 
                v-model="form.company_user.permissions.can_access_financial"
                label="Pode Acessar Financeiro"
                hide-details
              /> -->

              <v-switch
                v-model="form.company_user.permissions.can_manage_all_financial"
                class="pt-0"
                label="Pode alterar todo o financeiro"
                hide-details
              />

              <v-switch
                v-model="form.company_user.permissions.can_view_all_financial"
                class="pt-0"
                label="Visualizar todo o financeiro"
                hide-details
              />
              <template v-of="form.company_user.is_professional">
                <v-switch
                  v-model="
                    form.company_user.permissions.can_manage_own_financial
                  "
                  v-if="form.company_user.is_professional"
                  class="pt-0"
                  label="Pode alterar apenas seu financeiro"
                  hide-details
                />
                <!-- <v-switch
                  v-model="form.company_user.permissions.can_view_own_financial"
                  v-if="form.company_user.is_professional"
                  class="pt-0 "
                  label="Pode visualizar apenas seu financeiro"
                  hide-details
                /> -->
              </template>
            </template>

            <v-switch
              class="pt-6"
              label="Usuário ativo"
              v-model="form.company_user.status"
              true-value="active"
              false-value="inactive"
              hide-details
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn @click="dialog = false" text> Cancelar </v-btn>
        <v-btn @click="handleSave()" color="primary"> Salvar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AppImg from "@/components/app/sections/AppImg.vue";

export default {
  components: {
    AppImg,
  },
  data() {
    return {
      baseForm: {
        id: null,
        name: null,
        email: null,
        phone_wa: null,
        password: null,
        company_user: {
          is_professional: true,
          is_attendant: false,
          is_admin: false,
          permissions: {},
          status: "active",
        },
        main_address: {},
      },

      form: {
        company_user: {
          is_professional: true,
          permissions: {},
        },
      },

      dialog: false,
    };
  },

  computed: {
    isInsert() {
      return !this.form.id;
    },
    title() {
      return this.isInsert ? "Novo Usuário" : "Atualizar Usuário";
    },

    isProfessional() {
      return this.form.company_user.is_professional;
    },
  },

  created() {
    this.reset();
  },

  watch: {
    isProfessional(val) {
      this.form.company_user.is_attendant = !val;
    },
  },

  methods: {
    open(user_id) {
      this.reset();

      if (user_id) {
        this.showUser(user_id);
      }

      this.dialog = true;
    },

    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
    },

    handleSave() {
      this.$loading.start();
      if (this.isInsert) {
        this.storeUser();
      }
      if (!this.isInsert) {
        this.updateUser();
      }
    },

    storeUser() {
      this.$http
        .store("user/users", this.form)
        .then((response) => {
          this.processSaved();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    updateUser() {
      this.$http
        .update("user/users", this.form.id, this.form)
        .then((response) => {
          this.processSaved();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    showUser(user_id) {
      this.$loading.start();
      this.$http
        .show("user/users", user_id)
        .then((response) => {
          this.setUser(response.user);

          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    setUser(user) {
      if (
        !user.company_user.permissions ||
        user.company_user.permissions.length == 0
      ) {
        user.company_user.permissions = {};
      }

      this.form = JSON.parse(JSON.stringify(user));
    },

    processSaved() {
      this.dialog = false;
      this.$emit("store");
      this.$loading.finish();
    },
  },
};
</script>
