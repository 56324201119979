<template>
  <div>
    <v-row>
      <v-col cols="12" md="4">
        <app-text-search-field @input="handleSearchInput($event)" />
      </v-col>
      <v-col cols="12" md="8" class="align-center d-flex">
        <v-chip-group
          @change="select()"
          mandatory
          v-model="searchParams.status"
        >
          <v-chip color="primary" filter :value="'active'"> Ativos </v-chip>
          <v-chip outlined filter :value="'inactive'">Inativos</v-chip>
        </v-chip-group>
      </v-col>
    </v-row>
    <!-- <v-row>
      <v-col class="pb-0">
        <h3>Proprietário da conta</h3>
      </v-col>
    </v-row>
    <v-row v-if="owner">
      <v-col md="2" cols="6">
        <UserCard :user="owner" @edit="handleOpenStoreUserForm(owner.id)" />
      </v-col>
    </v-row> -->
    <v-row>
      <v-col class="pb-0">
        <h3>Profissionais ({{ professionalCount }})</h3>
      </v-col>
    </v-row>
    <v-row>
      <template v-for="(professional, index) in professionals">
        <v-col cols="6" md="2" :key="index">
          <UserCard
            :user="professional"
            @edit="handleOpenStoreUserForm(professional.id)"
          />
        </v-col>
      </template>
      <v-col v-if="canAddUsers" cols="6" md="2" class="align-center">
        <v-card
          elevation="0"
          style="height: 100%; border: thin dashed rgba(0, 0, 0, 0.12)"
        >
          <v-card-text
            class="d-flex align-center justify-center"
            style="height: 100%"
          >
            <v-btn
              large
              text
              color="primary"
              @click="handleOpenStoreUserForm()"
            >
              <app-icon>add</app-icon> Adicionar
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pb-0">
        <h3>Atendentes ({{ attendantCount }})</h3>
      </v-col>
    </v-row>
    <v-row>
      <template v-for="(attendant, index) in secretaries">
        <v-col cols="6" md="2" :key="index">
          <UserCard
            :user="attendant"
            @edit="handleOpenStoreUserForm(attendant.id)"
          />
        </v-col>
      </template>
      <v-col v-if="canAddUsers" cols="6" md="2" class="align-center">
        <v-card
          elevation="0"
          style="height: 100%; border: thin dashed rgba(0, 0, 0, 0.12)"
        >
          <v-card-text
            class="d-flex align-center justify-center"
            style="height: 100%"
          >
            <v-btn
              large
              text
              color="primary"
              @click="handleOpenStoreUserForm()"
            >
              <app-icon>add</app-icon> Adicionar
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <StoreUserForm ref="StoreUserForm" @store="select()" />
  </div>
</template>

<script>
import UserCard from "@/components/users/sections/UserCard.vue";
import StoreUserForm from "@/components/users/forms/StoreUserForm";

export default {
  components: { StoreUserForm, UserCard },

  data: () => ({
    headers: [
      { text: "Nome", align: "start", value: "name" },
      { text: "", align: "end", value: "actions" },
    ],

    loading: false,

    searchParams: {
      page: 1,
      status: "active",
    },

    users: {
      data: [],
    },
    activeCount: 0,
    attendantCount: 0,
    professionalCount: 0,
  }),

  created() {
    this.select();
  },

  computed: {
    professionals() {
      return this.users.data.filter(
        (user) =>
          user.company_user.is_professional && !user.company_user.is_owner
      );
    },

    secretaries() {
      return this.users.data.filter(
        (user) => user.company_user.is_attendant && !user.company_user.is_owner
      );
    },

    owner() {
      return this.users.data.find((user) => user.company_user.is_owner);
    },

    professionals_limit() {
      return this.$store.state.auth.company.professionals_limit;
    },

    canAddUsers() {
      return this.activeCount < this.professionals_limit;
    },
  },

  methods: {
    async select(page = 1) {
      this.$loading.start();

      this.searchParams.page = page;

      await this.$http
        .index("user/users", this.searchParams)
        .then((response) => {
          this.users = response.users;
          this.activeCount = response.activeCount;

          this.attendantCount = response.attendantCount;
          this.professionalCount = response.professionalCount;

          this.$loading.finish();
        })
        .catch((error) => this.$loading.finish());
    },

    handleSearchInput(text) {
      this.searchParams.text = text;
      this.select();
    },

    handleOpenStoreUserForm(id) {
      this.$refs.StoreUserForm.open(id);
    },

    handleDeleteUser(user_id) {
      this.$loading.start();

      this.$http
        .destroy("user/users", user_id)
        .then((response) => {
          this.select();
        })
        .catch((error) => this.$loading.finish());
    },
  },
};
</script>

<style></style>
